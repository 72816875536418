/**
 * @param {Object} position
 * @param {number} position.lat
 * @param {number} position.lng
 * @param {Location} location
 * @returns {Promise.<number>}
 */
export function getDistance(position, location) {
  if (position.lat === location.lat && position.lng === location.lng) {
    return Promise.resolve(0);
  }

  const radlat1 = (Math.PI * position.lat) / 180;
  const radlat2 = (Math.PI * location.lat) / 180;
  const theta = position.lng - location.lng;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344; // convert in kilometers

  return Promise.resolve(dist * 1000);
}
