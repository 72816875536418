import { generateRoute } from '../../../CoreBundle/Resources/private/js/routing';
import { isGoogleAnalyticsEnabled } from '../../../CoreBundle/Resources/private/js/plymouth/ga';
import { isMatomoEnabled } from '../../../CoreBundle/Resources/private/js/plymouth/matomo';

export default {
  name: 'YproxCallTrackingToggableTracker',
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    siteTitle: {
      type: String,
      required: true,
    },
    googleAnalyticsAccounts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tracked: false,
    };
  },
  methods: {
    track() {
      if (this.tracked) {
        return;
      }

      this.tracked = true;

      this.requestIncrement(this.id);
      this.sendEventToGoogleAnalytics();
      this.sendEventToMatomo();
    },
    /**
     * @private
     */
    requestIncrement() {
      const incrementPath = generateRoute('tracker_site_callracking_increment', {
        site_id: this.siteId,
      });

      // I won't import Axios just for doing this...
      const xhr = new XMLHttpRequest();
      xhr.open('POST', incrementPath, true);
      xhr.send(null);
    },
    sendEventToGoogleAnalytics() {
      if (isGoogleAnalyticsEnabled()) {
        global.gtag('event', 'hidden_content_revealed', {
          site_title: this.siteTitle,
          site_id: this.siteId,
        });
      }
    },
    sendEventToMatomo() {
      if (isMatomoEnabled()) {
        // eslint-disable-next-line no-underscore-dangle
        global._paq.push([
          'trackEvent',
          'Y360CallTrackingToggableTracker',
          'hidden_content_revealed',
          this.siteTitle,
          this.siteId,
        ]);
      }
    },
  },
  render() {
    if (typeof this.$scopedSlots.default !== 'function') {
      throw new Error('Missing default scoped slot, use <template #default="props"></template>.');
    }

    return this.$scopedSlots.default({
      tracked: this.tracked,
      track: this.track,
    });
  },
};
