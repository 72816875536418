/* global $ */

import * as _ from 'lodash';
import MarkerClusterer from 'js-marker-clusterer';

import { MapUtils } from './MapUtils';
import { LocatorUtils } from './LocatorUtils';
import { getDistance as getDistanceAsCrowFlies } from '../distances/crow-flies';
import { generateRoute } from '../../../../../../CoreBundle/Resources/private/js/routing';

/**
 * @param {Location} location
 */
const scrollToLocation = (location) => {
  const { siteId } = location;
  const selector = `[data-store-locator__site-id="${siteId}"]`;
  const $$store = $(selector);

  if ($$store.length === 1) {
    $('html, body').animate(
      {
        scrollTop: $$store.offset().top - 48, // offset of 48px to having a better render result
      },
      500
    );
  }
};

/**
 * Greeting config
 * @typedef {Object} MapServiceObject
 * @property {Site} site
 * @property {StoreLocator} storeLocator
 * @property {google.maps.Marker} marker
 * @property {google.maps.InfoWindow} infoWindow
 * @property {Location} location
 */

/**
 * @typedef {Object} Site
 * @property {number} id
 * @property {string} title
 */

/**
 * @typedef {Object} StoreLocator
 * @property {number} id
 */

/**
 * @typedef {Object} MapServiceConfig
 * @property {Object} map
 * @property {number} map.centerLat
 * @property {number} map.centerLng
 * @property {Boolean} map.displayAllMarkers
 * @property {number} map.displayXMarkers
 * @property {number} map.defaultZoom
 * @property {number} map.zoomAfterUpdate
 * @property {number} map.zoomAfterLocationSelect
 * @property {Object} marker
 * @property {Object} marker.icons
 * @property {String|null} marker.icons.default
 * @property {String|null} marker.icons.location
 * @property {String|null} marker.icons.active
 * @property {number} marker.minVisibleZoom
 * @property {number} marker.enableClusterer
 * @property {Boolean} marker.displaySearchMarker
 * @property {Boolean} marker.actionsOnMouseOver
 * @property {Boolean} marker.enableCustomStyles
 * @property {Array} marker.customStyles
 */

export class MapService {
  /**
   * @param {MapServiceConfig} config
   */
  constructor(config) {
    this.config = config;
    this.directionService = new global.google.maps.DirectionsService();
    /** @type {MapServiceObject[]} */
    this.objects = [];
    this.directions = [];
    this.eventListeners = [];
    /** @type {google.maps.Marker} */
    this.currentLocationMarker = null;
  }

  /**
   * @param {Element} element
   * @param {Array}   styles
   */
  initMap(element, styles = []) {
    this.map = MapUtils.createMap(element, this.config.map, styles);

    if (this.config.marker.enableClusterer) {
      // https://github.com/googlemaps/v3-utility-library/tree/%40google/markerclustererplus%402.1.12/packages/markerclustererplus
      this.clusterer = new MarkerClusterer(this.map, [], {
        imagePath: '/plugins/markerclusterer/images/m',
        styles: this.config.marker.enableCustomStyles ? this.config.marker.customStyles : [],
      });
    }

    if (this.autocomplete) {
      this.autocomplete.bindTo('bounds', this.map);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  pacSelectFirst(input) {
    const addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
      if (type === 'keydown') {
        const origListener = listener;

        // eslint-disable-next-line no-param-reassign
        listener = function (event) {
          const suggestionSelected = $('.pac-item-selected').length > 0;

          if ((event.which === 13 || event.which === 9) && !suggestionSelected) {
            const simulatedDownarrow = $.Event('keydown', { keyCode: 40, which: 40 });
            origListener.apply(input, [simulatedDownarrow]);
          }

          origListener.apply(input, [event]);
        };
      }

      addEventListener.apply(input, [type, listener]);
    }

    if (input.addEventListener) {
      // eslint-disable-next-line no-param-reassign
      input.addEventListener = addEventListenerWrapper;
    } else if (input.attachEvent) {
      // eslint-disable-next-line no-param-reassign
      input.attachEvent = addEventListenerWrapper;
    }
  }

  /**
   * @param {Element} element
   */
  initGoogleAutocomplete(element) {
    this.autocomplete = new global.google.maps.places.Autocomplete(element, {
      componentRestrictions: {
        country: this.config.autocomplete.countries,
      },
      fields: ['formatted_address', 'geometry', 'address_components', 'name'],
      types: ['geocode'],
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (place.geometry) {
        this.pushToDatabase(place);
        this.selectPlace(place);
      }
    });

    this.pacSelectFirst(element);

    if (this.map) {
      this.autocomplete.bindTo('bounds', this.map);
    }
  }

  /**
   * @param {google.maps.places.PlaceResult} place
   */
  pushToDatabase(place) {
    let region = null;
    let department = null;
    let city = null;

    place.address_components.forEach((element) => {
      if (element.types[0] === 'administrative_area_level_1') {
        region = element.long_name;
        return;
      }
      if (element.types[0] === 'administrative_area_level_2') {
        department = element.long_name;
        return;
      }
      if (element.types[0] === 'locality') {
        city = element.long_name;
      }
    });

    this.sendAutocompleteEvent(region, department, city, place.name);
  }

  sendAutocompleteEvent(region, department, city, name) {
    const formData = new FormData();
    if (region) {
      formData.append('event[region]', region);
    }
    if (department) {
      formData.append('event[department]', department);
    }
    if (city) {
      formData.append('event[city]', city);
    }
    if (name) {
      formData.append('event[name]', name);
    }
    fetch(generateRoute('store_locator_log_event', { store_locator_id: this.config.storeLocator.id }), {
      method: 'POST',
      body: formData,
    });
  }

  /**
   * @param {string} eventName
   * @param {Function} callback
   */
  addEventListener(eventName, callback) {
    this.eventListeners.push({ eventName, callback });
  }

  /**
   * @param {Location} location
   */
  addLocation(location) {
    const marker = MapUtils.createMarkerForLocation(location, this.config.marker);
    const infoWindow = MapUtils.createInfoWindowForLocation(location);
    /** @type MapServiceObject */
    const object = { location, marker, infoWindow };

    google.maps.event.addListener(marker, 'click', () => {
      this.closeInfoWindows();
      this.resetMarkerIcons();
      this.openInfoWindow(location);
      marker.setIcon(this.config.marker.icons.active || this.config.marker.icons.default);
    });

    if (this.config.marker.actionsOnMouseOver === true) {
      google.maps.event.addListener(marker, 'mouseover', () => {
        this.closeInfoWindows();
        this.openInfoWindow(location);
        scrollToLocation(location);
      });
    }

    this.addObject(object);
  }

  /**
   * @param {Object} position
   * @param {string} iconLocation
   */
  addMarkerLocation(position, iconLocation) {
    if (!this.config.marker.displaySearchMarker) {
      return;
    }

    const geocoder = new global.google.maps.Geocoder();
    const infowindow = new global.google.maps.InfoWindow();
    const latlng = { lat: parseFloat(position.lat), lng: parseFloat(position.lng) };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        this.deleteMarkerLocation(); // remove previous marker

        const currentLocationMarker = new global.google.maps.Marker({
          icon: iconLocation || MapService.ICON_DEFAULT_LOCATION,
          optimized: false,
          position: latlng,
          map: this.map,
        });
        this.currentLocationMarker = currentLocationMarker;
        infowindow.setContent(results[0].formatted_address);
        infowindow.open(this.map, currentLocationMarker);
      }
    });
  }

  /**
   * @param {string} address
   * @param {string} country
   */
  geocode(address, country) {
    const geocoder = new global.google.maps.Geocoder();

    geocoder.geocode(
      {
        address,
        componentRestrictions: {
          country,
        },
      },
      (results, status) => {
        if (status === 'OK') {
          const position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            name: results[0].formatted_address,
          };

          this.emitEvent(MapService.POSITION_CHANGED, position);
        }
      }
    );
  }

  deleteMarkerLocation() {
    if (!this.config.marker.displaySearchMarker) {
      return;
    }

    if (this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null);
    }
  }

  /**
   * @private
   * @param {MapServiceObject} object
   */
  addObject(object) {
    this.objects.push(object);

    if (this.clusterer) {
      this.clusterer.addMarker(object.marker);
    } else {
      object.marker.setMap(this.map);
    }
  }

  /**
   * @param {Location} location
   */
  removeLocation(location) {
    const object = this.getObjectByLocation(location);

    if (object) {
      this.removeObject(object);
    }
  }

  /**
   * @private
   * @param {MapServiceObject} object
   */
  removeObject(object) {
    const objectIndex = this.objects.indexOf(object);

    if (objectIndex !== -1) {
      this.objects.splice(objectIndex, 1);
    }

    if (this.clusterer) {
      this.clusterer.removeMarker(object.marker);
    } else {
      object.marker.setMap(null);
    }
  }

  /**
   * @param {Location} location
   *
   * @returns {boolean}
   */
  hasLocation(location) {
    return !!this.getObjectByLocation(location);
  }

  /**
   * @private
   * @param {Location} location
   *
   * @return {?MapServiceObject}
   */
  getObjectByLocation(location) {
    return this.objects.filter((object) => object.location.storeId === location.storeId)[0];
  }

  /**
   * @param {Location[]} locations
   */
  setLocations(locations) {
    const objects = [...this.objects];
    let bounds = new global.google.maps.LatLngBounds();

    locations.forEach((location) => {
      const object = this.getObjectByLocation(location);
      const objectIndex = objects.indexOf(object);

      bounds.extend({ lat: location.lat, lng: location.lng });

      if (objectIndex !== -1) {
        objects.splice(objectIndex, 1);
      } else {
        this.addLocation(location);
      }
    });

    if (locations.length > 0) {
      const onBoundsChangedListener = google.maps.event.addListener(this.map, 'bounds_changed', () => {
        google.maps.event.removeListener(onBoundsChangedListener);
      });

      if (this.config.map.displayAllMarkers === true) {
        this.map.fitBounds(bounds);
      } else if (this.config.map.displayXMarkers > 0) {
        bounds = new global.google.maps.LatLngBounds();
        let nbMarkers = this.config.map.displayXMarkers;
        if (locations.length < nbMarkers) {
          nbMarkers = locations.length;
        }
        for (let i = 0; i < nbMarkers; i += 1) {
          const marker = locations[i];
          if ('lat' in marker && 'lng' in marker) {
            const loc = new global.google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.lng));
            bounds.extend(loc);
          }
        }
        this.map.fitBounds(bounds);
        this.map.panToBounds(bounds);
      } else {
        let stop = false;
        while (!stop && this.map.getZoom() > this.config.map.defaultZoom) {
          for (let i = 0; i < locations.length; i += 1) {
            const marker = locations[i];
            const newMap = new global.google.maps.LatLng(parseFloat(marker.lat), parseFloat(marker.lng));
            if (this.map.getBounds().contains(newMap) === true) {
              stop = true;
            }
          }
          if (!stop) {
            this.map.setZoom(this.map.getZoom() - 1);
          }
        }
      }

      // If we have only one location, ensure to zoom like for a location selection
      // otherwise it's TOO much zoomed because of bounds and .fitBounds() method.
      if (locations.length === 1) {
        this.map.setZoom(this.config.map.zoomAfterLocationSelect);
      }
    }

    objects.forEach((object) => {
      this.removeObject(object);
    });
  }

  /**
   * @param {Location} location
   */
  selectLocation(location) {
    if (!this.map) {
      throw new Error('Missing map instance. Use initMap() before.');
    }

    const object = this.getObjectByLocation(location);

    if (object) {
      this.map.setCenter(object.marker.position);
      this.map.setZoom(this.config.map.zoomAfterLocationSelect);

      this.resetMarkerIcons();
      this.closeInfoWindows();
      this.openInfoWindow(location);

      object.marker.setIcon(this.config.marker.icons.active || this.config.marker.icons.default);
    }
  }

  closeInfoWindows() {
    this.objects.forEach((object) => {
      object.infoWindow.close();
    });
  }

  resetMarkerIcons() {
    this.objects.forEach((object) => {
      object.marker.setIcon(this.config.marker.icons.default);
    });
  }

  /**
   * @param {Location} location
   */
  openInfoWindow(location) {
    const object = this.getObjectByLocation(location);

    if (object) {
      object.infoWindow.open(this.map, object.marker);
    }
  }

  /**
   * @param {google.maps.places.PlaceResult} place
   */
  selectPlace(place) {
    if (!this.map) {
      throw new Error('Missing map instance. Use initMap() before.');
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(this.config.map.zoomAfterUpdate);
    }

    const position = {
      name: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    this.emitEvent(MapService.POSITION_CHANGED, position);
  }

  /**
   * @param {Object} position
   * @param {number} position.lat
   * @param {number} position.lng
   * @param {Location} location
   * @param {string} distancesProvider
   * @returns {Promise.<number>}
   */
  getDistance(position, location, distancesProvider) {
    if (distancesProvider === 'GOOGLE_DIRECTIONS') {
      return this.getDirectionsRepetitive(position, location).then((result) =>
        Promise.resolve(result.routes[0].legs[0].distance.value)
      );
    }

    if (distancesProvider === 'CROW_FLIES') {
      return getDistanceAsCrowFlies(position, location);
    }

    throw new Error(`Invalid distances provider "${distancesProvider}".`);
  }

  destroyMap() {
    if (this.clusterer) {
      this.clusterer.clearMarkers();
    }
  }

  /**
   * @private
   * @param {string} eventName
   * @param {*} data
   */
  emitEvent(eventName, ...data) {
    this.eventListeners
      .filter((listener) => listener.eventName === eventName)
      .forEach((listener) => {
        listener.callback(...data);
      });
  }

  /**
   * Persistently tries to get directions if OVER_QUERY_LIMIT or UNKNOWN_ERROR status was returned.
   *
   * @private
   * @param {Object} position
   * @param {number} position.lat
   * @param {number} position.lng
   * @param {Location} location
   * @param {number} delay
   * @param {number} tryCount
   * @returns {Promise.<google.maps.DirectionsResult>}
   */
  getDirectionsRepetitive(position, location, delay = 1000, tryCount = 3) {
    return this.getDirections(position, location).catch((error) => {
      if (['OVER_QUERY_LIMIT', 'UNKNOWN_ERROR'].indexOf(error) !== -1 && tryCount > 0) {
        return LocatorUtils.delayedPromise(delay).then(() =>
          this.getDirectionsRepetitive(position, location, delay, tryCount - 1)
        );
      }

      return Promise.reject();
    });
  }

  /**
   * @private
   * @param {Object} position
   * @param {number} position.lat
   * @param {number} position.lng
   * @param {Location} location
   * @returns {Promise.<google.maps.DirectionsResult>}
   */
  getDirections(position, location) {
    let direction = _.find(this.directions, { locationId: location.storeId });

    if (direction === undefined) {
      direction = {
        locationId: location.storeId,
        results: [],
      };

      this.directions.push(direction);
    }

    const origin = {
      lat: position.lat,
      lng: position.lng,
    };

    let result = _.find(direction.results, origin);

    if (result !== undefined) {
      return Promise.resolve(result.data);
    }

    const config = {
      origin,
      destination: {
        lat: location.lat,
        lng: location.lng,
      },
      travelMode: 'DRIVING',
    };

    return new Promise((resolve, reject) => {
      this.directionService.route(config, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          result = {
            ...origin,
            data: response,
          };

          direction.results.push(result);

          resolve(response);
        } else {
          reject(status);
        }
      });
    });
  }
}

MapService.POSITION_CHANGED = 'POSITION_CHANGED';
MapService.ICON_DEFAULT_LOCATION = 'https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png';
