export const LocatorAroundRadius = {
  name: 'locator-around-radius',
  template: '#yprox-store-locator-around-radius-template',
  model: {
    prop: 'internalValue',
    event: 'change',
  },
  props: {
    internalValue: {
      type: [String, Number],
      required: true,
    },
    aroundRadiuses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.internalValue;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
