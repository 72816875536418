import Vue from 'vue';
import queryString from 'query-string';
import { ensureArray } from '../util';

export const LocatorFilterList = {
  name: 'locator-filter-list',
  template: '#yprox-store-locator-filter-list-template',
  props: {
    property: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    propertyValues: {
      type: Object,
      required: true,
    },
  },
  computed: {
    values: {
      get() {
        return this.filter[this.property.name] || [];
      },
      set(values) {
        Vue.set(this.filter, this.property.name, values);
      },
    },
  },
  created() {
    const urlParameters = queryString.parse(global.location.search);

    if (this.property.urlFilter && urlParameters[this.property.urlFilter]) {
      const prefilledValues = ensureArray(urlParameters[this.property.urlFilter]);

      this.values = this.propertyValues[this.property.name]
        .filter((value) => prefilledValues.includes(value.name))
        .map((value) => value.name);
    }
  },
  methods: {
    toggleItem(item) {
      const values = [...this.values];
      const itemIndex = values.indexOf(item);

      if (itemIndex !== -1) {
        values.splice(itemIndex, 1);
      } else {
        values.push(item);
      }

      this.values = values;
    },
    isItemSelected(item) {
      return this.values.indexOf(item) !== -1;
    },
  },
};
