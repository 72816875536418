import { LocatorListItem } from './LocatorListItem';

export const LocatorList = {
  name: 'locator-list',
  template: '#yprox-store-locator-list-template',
  components: {
    LocatorListItem,
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
    selectedLocation: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      required: false,
    },
    reviewRatings: {
      type: Object,
      required: false,
    },
    userHasTriggeredSearch: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isSelected(location) {
      if (this.selectedLocation === null) {
        return false;
      }

      return this.selectedLocation === location || this.selectedLocation.storeId === location.storeId;
    },
  },
};
